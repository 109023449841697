/* eslint-disable */
import axios, { AxiosError, AxiosResponse, AxiosStatic } from "axios";
import GetApiBaseUrl from "./ApiBaseUrl";
import AuthService from "./auth/AuthService";
import ToastService from "./helper/ToastService";
import router from "@/router";

export default class HttpService {

  static getAxiosInstance(): AxiosStatic {
    axios.create();
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error: AxiosError) => {
        console.log("error", error.toJSON());
        if (error !== undefined && error.status && Number(error.status) === 401) {
          if (
            error.config.url !== undefined &&
            error.config.url.endsWith("/token/refresh/")
          ) {
            AuthService.logout();
            ToastService.Error(
              error.status,
              "Login abgelaufen"
            );
            router.push("/login");
          } else {
            AuthService.refresh();
          }
        }
        console.log(error);
        if (
          error.response !== undefined &&
          error.message === "Request failed with status code 404"
        ) {
          //ToastService.Warning("404", "Keine Daten vorhanden.");
        } else {
          ToastService.Error("" + error.code?.toString(), error.message);
        }

        return error;
      }
    );
    return axios;
  }

  static checkIsADjangoList<T>(response: AxiosResponse<T>) {
    let response_data = response.data as any;
    // Is a Object list
    if (response_data !== undefined && response_data.count !== undefined) {
      return response_data.results as T;
    }
    // Single Object
    return response.data as T;
  }

  static async get<T>(url: string): Promise<T | undefined> {
    console.log("GET: ", url);
    try {
      const response = await this.getAxiosInstance().get<T>(
        GetApiBaseUrl() + url,
        { headers: AuthService.GetAuthHeader() }
      );
      return this.checkIsADjangoList<T>(response);
    } catch (e) {
      console.log("GET error: ", e);
    }
  }

  static async post<T>(
    url: string,
    payload: T,
    isMulitPart = false
  ): Promise<T | undefined> {
    console.log("POST: ", url);
    const postheader = isMulitPart
      ? AuthService.GetAuthHeader("multipart/form-data")
      : AuthService.GetAuthHeader();
    //console.log("postheader", postheader);
    try {
      const response = await this.getAxiosInstance().post<T>(
        GetApiBaseUrl() + url,
        payload,
        {
          headers: postheader,
        }
      );
      return this.checkIsADjangoList<T>(response);
    } catch (e) {
      console.log("POST error: ", e);
    }
  }

  static async put<T>(url: string, payload: T): Promise<T | undefined> {
    console.log("PUT: ", url);
    try {
      const response = await this.getAxiosInstance().put<T>(
        GetApiBaseUrl() + url,
        payload,
        { headers: AuthService.GetAuthHeader() }
      );
      return this.checkIsADjangoList<T>(response);
    } catch (e) {
      console.log("PUT error: ", e);
    }
  }

  static async delete<T>(url: string): Promise<any> {
    console.log("DELETE: ", url);
    try {
      const response = await this.getAxiosInstance().delete<T>(
        GetApiBaseUrl() + url,
        { headers: AuthService.GetAuthHeader() }
      );
      return this.checkIsADjangoList<T>(response);
    } catch (e) {
      console.log("DELETE error: ", e);
    }
  }
}
